import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import SolutionCard from './SolutionCard'

const SolutionsCarousel = ({indexItem, setIndexItem, solutionList}) => {


    return (
        <Tabs className='position-relative' selectedIndex={indexItem} onSelect={(index) => setIndexItem(index)}>
            <TabList className={"ps-0 position-sticky z-1 bg-white pt-2"} style={{top:'4rem'}}>
                <ul className="nav nav-pills mb-3 justify-content-start">
                    <Tab key={0}  className="nav-item me-2 mb-1">
                        <div
                        role="button" 
                        data-bs-toggle="pill" 
                        data-bs-target={`#strategy-${0}`}
                        className={`nav-link bg-body-secondary px-2 py-1 d-flex align-items-center flex-shrink-0 rounded-0 fs-8s active`}>
                            <i className=' fa fa-circle color-primary fs-4s me-1'></i>Tout
                        </div>
                    </Tab>
                    {
                        solutionList.categories.map((sol,index) =>(
                            <Tab key={index+1}  className="nav-item me-2 mb-1">
                                <div
                                role="button" 
                                data-bs-toggle="pill" 
                                data-bs-target={`#strategy-${index+1}`}
                                className={`nav-link bg-body-secondary px-2 py-1 d-flex align-items-center flex-shrink-0 rounded-0 fs-8s`}>
                                    <i className=' fa fa-circle color-primary fs-4s me-1'></i>{sol.name}
                                </div>
                            </Tab>
                        ))
                    }
                </ul>
            </TabList>

            <TabPanel key={0}>
                <div className='row justify-content-start align-items-center'>
                    {
                        solutionList.categories.map((sol,index) =>(
                            sol.list.map((item, index3) =>(
                                <div className='col-md-4 col-12 mb-3'>
                                    <SolutionCard 
                                    key={index3} 
                                    description={item.description} 
                                    image={item.image} 
                                    title={item.title} 
                                    category={sol.name} 
                                    icon={solutionList.icon}
                                    objectif={"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde?"}
                                    result={"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde? Sapiente, adipisci. Incidunt fugiat ipsum dolore distinctio temporibus. Totam iusto animi perspiciatis nisi explicabo alias deleniti nemo, optio adipisci amet laudantium voluptatum corrupti sint consequuntur in beatae illum! Tenetur, neque? Nesciunt fuga deleniti explicabo soluta atque! "}
                                    />
                                </div>
                            ))
                        ))
                    }
                </div>
            </TabPanel>
            {
                solutionList.categories.map((sol,index) =>(
                    <TabPanel key={index+1}>
                        <div className='row justify-content-start align-items-center'>
                            {
                                sol.list.map((item, index3) =>(
                                    <div className='col-md-4 col-12 mb-3'>
                                        <SolutionCard 
                                        key={index3} 
                                        description={item.description} 
                                        image={item.image} 
                                        title={item.title} 
                                        category={sol.name} 
                                        icon={solutionList.icon}
                                        objectif={"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde?"}
                                        result={"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde? Sapiente, adipisci. Incidunt fugiat ipsum dolore distinctio temporibus. Totam iusto animi perspiciatis nisi explicabo alias deleniti nemo, optio adipisci amet laudantium voluptatum corrupti sint consequuntur in beatae illum! Tenetur, neque? Nesciunt fuga deleniti explicabo soluta atque! "}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </TabPanel>
                ))
            }
        </Tabs>
    )
}

export default SolutionsCarousel