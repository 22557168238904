import React, { useState } from 'react'

import SolutionsCarousel from '../components/SolutionsCarousel'
import { strategySolutions } from '../constants/array'
import { Helmet } from 'react-helmet-async';


const Realisations = () => {


    const[stratIndex, setStratIndex] = useState(0)

    return (
        <div>

            
            
            {/* Header */}
            <Helmet>
                <title>Portfolio | CS COMMUNICATIONS, Together, we Believe...</title>
            </Helmet>

            {/* HERO */}
            <div className="bgcolor-primary">
                <div className="container h-100" >
                    <div className="d-flex justify-content-start align-items-center py-5 h-100" style={{minHeight: '70vh',  maxWidth: '992px'}}>
                        <div className='mt-5 h-100'>
                            <h1 data-aos="fade-right" data-aos-delay="300" className='cs-title fw-bold fs-2 text-white mb-3'>Des idées concrètes, un résultats visibles 
                                <span className="title-line-white my-4"></span>
                            </h1>
                            <p className='fs-9s text-white'  data-aos="fade-right" data-aos-delay="500">
                            Chaque collaboration est une histoire unique. Découvrez nos réalisations à travers différents domaines où la créativité et la stratégie se rencontrent
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* ABOUT */}
            <div className="container my-4 my-md-5 pb-md-5 pb-3 overflow-hidden">
                <h1 data-aos="fade-up" data-aos-duration="700" className='cs-title fs-2  mt-3 mx-auto text-center fw-bold'>
                    C'est dans la vieille marmite qu'on fait la bonne sauce
                    <p className='fs-6 text-black-50 fw-medium'>Proverbe africain</p>
                    <span className="title-line mx-auto mt-2"></span>
                </h1>


                <div className='mt-3 mt-md-4 pt-2' data-aos="zoom-in" data-aos-offset="100" data-aos-duration="1000">
                    <SolutionsCarousel 
                    indexItem={stratIndex}
                    setIndexItem={setStratIndex}
                    solutionList={strategySolutions}/>
                </div>
            </div>







        </div>
    )
}

export default Realisations