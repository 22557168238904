import React from 'react'
import { csFacebook, csInstagram, csLinkedIn, csYoutube } from '../constants/links'

const Footer2 = () => {
    return (
        <footer className='container  mt-auto mx-auto'>
            <div className='d-md-block d-none pb-md-3 pb-2'>
                <div className='d-flex justify-content-md-start justify-content-center align-items-center mt-1'>
                    <div className=' d-flex justify-content-center align-items-center bg-white bg-opacity-25 border rounded-circle' style={{width:"1.7rem", height:"1.7rem"}}><a href={csFacebook} target='_BLANK' rel='noreferrer' ><i className=' text-white fa-brands fs-9s fa-facebook-f'></i></a></div>
                    <div className=' d-flex justify-content-center align-items-center bg-white bg-opacity-25 border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csInstagram} target='_BLANK' rel='noreferrer' ><i className=' text-white fa-brands fs-9s fa-instagram'></i></a></div>
                    <div className=' d-flex justify-content-center align-items-center bg-white bg-opacity-25 border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csLinkedIn} target='_BLANK' rel='noreferrer' ><i className=' text-white fa-brands fs-9s fa-linkedin-in'></i></a></div>
                    <div className=' d-flex justify-content-center align-items-center bg-white bg-opacity-25 border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csYoutube} target='_BLANK' rel='noreferrer' ><i className=' text-white fa-brands fs-9s fa-youtube'></i></a></div>
                    <div className=' d-flex align-items-center justify-content-md-start justify-content-center ms-3'>
                        <i className='fa fa-phone text-white pe-1 fs-8s' ></i>
                        <a className=' text-white fs-9s' style={{textShadow:'rgb(0, 0, 0) 2px 1px 3px'}} href='tel:652522020'>6 52 52 20 20</a>
                    </div>
                    <small className=' fs-8s text-white text-center d-flex justify-content-center ms-3'> Copyright &copy; CS COMMUNICATIONS, All Rights Reserved</small>
                </div>
            </div>

            <div className='d-md-none d-block mt-4'>
                <div className=' d-flex align-items-center justify-content-center mb-2'>
                    <i className='fa fa-phone color-primary pe-1 fs-8s'></i>
                    <a className=' text-black fs-9s' href='tel:652522020'>6 52 52 20 20</a>
                </div>
                <div className='d-flex justify-content-center mb-3'>
                    <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle' style={{width:"1.7rem", height:"1.7rem"}}><a href={csFacebook} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-facebook-f'></i></a></div>
                    <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csInstagram} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-instagram'></i></a></div>
                    <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csLinkedIn} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-linkedin-in'></i></a></div>
                    <div className=' d-flex justify-content-center align-items-center bg-white border rounded-circle ms-2' style={{width:"1.7rem", height:"1.7rem"}}><a href={csYoutube} target='_BLANK' rel='noreferrer' ><i className=' fa-brands fs-9s fa-youtube'></i></a></div>
                </div>
                <small className=' fs-8s text-black-50 text-center pb-2 d-flex justify-content-center'> Copyright &copy; CS COMMUNICATIONS, All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer2