import React from 'react'
import serviceImg from '../assets/images/Services.jpg'
import { servicesArray } from '../constants/array'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'

const Services = () => {
    return (
        <div>
            
            
            {/* Header */}
            <Helmet>
                <title>Services | CS COMMUNICATIONS, Together, we Believe...</title>
            </Helmet>


            {/* HERO */}
            <div className="bgcolor-primary">
                <div className="container h-100" >
                    <div className="d-flex justify-content-start align-items-center py-5 h-100" style={{minHeight: '70vh',  maxWidth: '992px'}}>
                        <div className='mt-5 h-100'>
                            <h1 data-aos="fade-right" data-aos-delay="300" className='cs-title fw-bold fs-2 text-white mb-3'>CS COMMUNICATIONS, <br/> une vision d’ensemble pour optimiser les résultats.
                                <span className="title-line-white my-4"></span>
                            </h1>
                            <div className='fs-9s text-white'  data-aos="fade-right" data-aos-delay="500">
                                <p className='fs-9s mb-2'>Chez <strong className='fs-9s'>CS COMMUNICATIONS,</strong> nous savons que la réussite d’une marque passe par une stratégie digitale cohérente et percutante. C’est pourquoi nous proposons une <strong className='fs-9s'>offre 360°,</strong> une approche globale qui couvre tous les leviers du digital pour vous offrir une solution clé en main.</p>
                                <p className='fs-9s mb-2'>De la <strong className='fs-9s'>stratégie à l’exécution,</strong> en passant par le <strong className='fs-9s'>branding, la création de contenu, la publicité en ligne et le développement web,</strong> nous intégrons tous les aspects de votre projet pour garantir une communication harmonieuse et efficace.</p>
                                <p className='fs-9s mb-2'>Avec nous, <strong className='fs-9s'>pas de solutions fragmentées,</strong> mais une vision d’ensemble qui maximise votre impact et optimise vos résultats. <strong className='fs-9s'>Un seul interlocuteur, une stratégie unifiée, des performances décuplées !</strong></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* SERVICES */}
            <div className="container my-4 my-md-5 pb-md-4 pb-3 overflow-hidden">
                <h1 data-aos="fade-up" data-aos-duration="700" className='cs-title fs-2  mt-3 mx-auto text-center fw-bold'>
                    Au service des idées, même les plus folles...
                    <span className="title-line mx-auto mt-2"></span>
                </h1>


                <div className="row justify-content-around align-items-stretch mt-4 mt-md-5 pt-2">
                    <div className="col-md-6 col-12">
                        <div data-aos="zoom-in" data-aos-duration="700" className='position-relative h-100'>
                            <img src={serviceImg} alt="services de cs communications" className="img-fluid w-100 h-100 object-fit-cover service-img" />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div>
                            <Accordion defaultActiveKey={`service-0`} data-aos="fade-up" data-aos-duration="700" className='bg-transparent' flush>
                                {
                                    servicesArray.map((service, index) => (
                                        <Accordion.Item eventKey={`service-${index}`} className='bg-transparent' style={{background: "transparent"}}>
                                            <Accordion.Header>{service.title}</Accordion.Header>
                                            <Accordion.Body className='py-2'>
                                                {service.list.map((elt, eltIndex) =>(
                                                    <div key={eltIndex} className=' d-flex justify-content-start align-items-start'>
                                                        <i className={`${service.icon} fs-7s color-primary me-1`} style={{marginTop:'.4rem'}}></i>
                                                        <p key={index} className='mb-1 fs-6'>{elt}</p>
                                                    </div>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Services
