import React from 'react'
import serviceImg from '../assets/images/agence.jpg'
import { aboutArray } from '../constants/array'
import { Accordion } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'


const Agency = () => {
    return (
        <div>


            {/* Header */}
            <Helmet>
                <title>Agence | CS COMMUNICATIONS, Together, we Believe...</title>
            </Helmet>

            {/* HERO */}
            <div className="bgcolor-primary">
                <div className="container h-100" >
                    <div className="d-flex justify-content-start align-items-center py-5 h-100" style={{minHeight: '70vh',  maxWidth: '992px'}}>
                        <div className='mt-5 h-100'>
                            <h1 data-aos="fade-right" data-aos-delay="300" className='cs-title fw-bold fs-2 text-white mb-3'>Á CS COMMUNICATIONS, nous créons des expériences engageantes qui propulsent votre marque vers le succès. 
                                <span className="title-line-white my-4"></span>
                            </h1>
                            <p className='fs-9s text-white'  data-aos="fade-right" data-aos-delay="500">
                            Fondée en 2012 avec la conviction que le digital représenterait un tournant décisif dans le développement des marques, notre agence s’est positionnée comme un maillon important dans l’accompagnement des marques dans l’écosystème digital. Depuis plus de 10 ans, grâce à une <strong className='fs-9s'>équipe de professionnels passionnés et expérimentés</strong>, nous accompagnons les entreprises dans leurs communications numériques en leur offrant des <strong className='fs-9s'>solutions innovantes, créatives et performantes</strong> pour les propulser vers le succès
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            {/* ABOUT */}
            <div className="container my-4 my-md-5 pb-md-5 pb-3 overflow-hidden">
                <h1 data-aos="fade-up" data-aos-duration="700" className='cs-title fs-2  mt-3 mx-auto text-center fw-bold'>
                    C'est dans la vieille marmite qu'on fait la bonne sauce
                    <p className='fs-6 text-black-50 fw-medium'>Proverbe africain</p>
                    <span className="title-line mx-auto mt-2"></span>
                </h1>

                <div className="row justify-content-around align-items-stretch mt-4 mt-md-5 pt-2">
                    <div className="col-md-6 col-12">
                        <div data-aos="zoom-in" data-aos-duration="700" className='position-relative h-100'>
                            <img src={serviceImg} alt="services de cs communications" className="img-fluid w-100 h-100 object-fit-cover service-img" />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div>
                            <Accordion defaultActiveKey={`about-0`} data-aos="fade-up" data-aos-duration="700" className='bg-transparent' flush>
                                {
                                    aboutArray.map((about, index) => (
                                        <Accordion.Item eventKey={`about-${index}`} className='bg-transparent' style={{background: "transparent"}}>
                                            <Accordion.Header className='fw-bold'>{about.title}</Accordion.Header>
                                            <Accordion.Body className='py-2'>
                                                {
                                                    about.list ?
                                                        about.list.map((item, index) => (
                                                            <div className=' d-flex justify-content-start align-items-start'>
                                                                <i className=' fa fa-angle-double-right fs-7s color-primary me-1 mt-1'></i>
                                                                <p key={index} className='mb-1 fs-9s'> <strong className='fs-9s'>{item.value}</strong> : {item.text}</p>
                                                            </div>
                                                        ))
                                                    : 
                                                    <p className='mb-0 fs-9s'>{about.text} </p>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))
                                }
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Agency
