import React from 'react'

// IMPORT GIF

import { logo, placeholderImg } from '../constants/files'
import { csEmail, csFacebook, csInstagram, csLinkedIn, csLocation, csLocationLink, csTel, csYoutube } from '../constants/links'
import { NavLink } from 'react-router-dom'
import { navLinks } from '../constants/array'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = () => {
    return (
        <footer className='mt-4 pt-3'>
            <div className=' main-footer z-2'>
                <div className='container'>
                    <div className='row justify-content-between align-items-start'>

                        {/* logo */}
                        <div className=' col-lg-3 col-md-6 col-12 mt-3 align-self-start '>
                            <LazyLoadImage 
                            src={logo} 
                            alt='CS COMMUNICATIONS' 
                            placeholder={<div className=' w-100 h-100'><img src={placeholderImg} className=' w-50 h-50 object-fit-cover' alt='' /></div>}
                            effect="black-and-white"
                            className='img-fluid' 
                            style={{maxHeight: '5rem'}} />
                        </div>



                        {/* useful links */}
                        <div className=' col-lg-3 col-md-6 col-12 mt-3'>
                            <h4 className=' color-primary fw-bold'>Liens utiles</h4>
                            {
                                navLinks.map((elt,index) =>(
                                    elt.link?
                                    <div key={index} className=' d-flex align-items-center'>
                                            <i className=' fa fa-angle-double-right color-primary fs-8s me-2'></i>
                                            <NavLink className='text-black fs-9s' to={elt.link}>{elt.nav} </NavLink>
                                        </div>
                                    :elt.subNav.map((sub,index) =>(
                                        null
                                    ))
                                ))
                            }
                        </div>
                        


                        {/* contacts */}
                        <div className=' col-lg-3 col-md-6 col-12 mt-3'>
                            <h4 className=' color-primary fw-bold'>Contacts</h4>
                            <div className=' d-flex align-items-center'>
                                <i className='fa fa-envelope color-primary fs-8s me-2'></i>
                                <a href={'mailto:'+csEmail} className=' text-black fs-9s'> {csEmail} </a>
                            </div>
                            <div className=' d-flex align-items-center'>
                                <i className='fa fa-phone color-primary fs-8s me-2'></i>
                                <a href={'tel:'+csTel} className=' text-black fs-9s'> {csTel} </a>
                            </div>
                            <div className=' d-flex align-items-center'>
                                <i className='fa fa-thumb-tack color-primary fs-8s me-2'></i>
                                <a href={csLocationLink} target='_blank' rel='noreferrer' className=' text-black fs-9s'> {csLocation} </a>
                            </div>
                        </div>



                        {/* RS */}
                        <div className=' col-lg-auto col-md-6 col-12 my-3'>
                            <h4 className=' color-primary fw-bold'>Nos réseaux</h4>
                            <div className='d-flex justify-content-start align-items-center'>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow-sm rounded-circle fs-8s' style={{width:"1.8rem", height:"1.8rem"}}><a href={csFacebook} target='_BLANK' rel='noreferrer' ><i className=' fa-brands color-primary fa-facebook-f'></i></a></div>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow-sm rounded-circle fs-8s ms-2' style={{width:"1.8rem", height:"1.8rem"}}><a href={csInstagram} target='_BLANK' rel='noreferrer' ><i className=' fa-brands color-primary fa-instagram'></i></a></div>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow-sm rounded-circle fs-8s ms-2' style={{width:"1.8rem", height:"1.8rem"}}><a href={csYoutube} target='_BLANK' rel='noreferrer' ><i className=' fa-brands color-primary fa-youtube'></i></a></div>
                                <div className=' d-flex justify-content-center align-items-center bg-white shadow-sm rounded-circle fs-8s ms-2' style={{width:"1.8rem", height:"1.8rem"}}><a href={csLinkedIn} target='_BLANK' rel='noreferrer' ><i className=' fa-brands color-primary fa-linkedin-in'></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' py-2 mt-1 bg-image-footer'>
                <div className='container h-100'>
                    <div className='w-100 h-100 d-flex justify-content-center align-items-end fs-7s fw-medium'>
                        Tous droits de reproduction et de diffusion réservés © 2025 CS COMMUNICATIONS
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer