import React from 'react'

// timeline style component
import 'react-vertical-timeline-component/style.min.css';
import { heroCarouselArray } from '../constants/array'

import Carousel from 'react-multi-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { placeholderImg } from '../constants/files';
import Footer2 from '../components/Footer2';
import { Helmet } from 'react-helmet-async';


const Home = () => {



    const responsive = {
        desktop: {
            breakpoint: { max: 6000, min: 768 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    }

    
    return (
        <div>


            <Helmet>
                <title>CS COMMUNICATIONS, Together, we Believe...</title>
            </Helmet>


            {/* Hero */}
            <section className=' hero-carousel position-relative'>
                <Carousel 
                responsive={responsive} 
                infinite 
                showDots 
                draggable 
                swipeable 
                autoPlay 
                autoPlaySpeed={7000} 
                keyBoardControl={true} 
                transitionDuration={1000} 
                removeArrowOnDeviceType={["tablet", "mobile"]} 
                className='position-relative overflow-hidden w-100 h-100'>
                    {
                        heroCarouselArray.map((elt,index) =>(        
                            <div className='position-relative w-100 h-100' key={index}>
                                <LazyLoadImage 
                                className=' object-fit-cover w-100 h-100' 
                                src={elt.img} 
                                placeholder={<div className=' w-100 h-100'><img src={placeholderImg} className=' w-100 h-100 object-fit-cover' alt='' /></div>}
                                effect="black-and-white"
                                wrapperClassName=' h-100 w-100'
                                alt={"CS COMMUNICATIONS " + elt.text}  />
                                <div className=' w-100 h-100 d-flex justify-content-start align-items-center bg-primary bg-opacity-10 position-absolute top-0 end-0 z-2 py-5'>
                                    <div className=' container text-white'>
                                        <div className='row justify-content-start'>
                                            <div className='col-md-8'>
                                                <h1 className=' fw-bold ms-md-2 mb-3 title-size-2' style={{textShadow:"2px 1px 3px #000"}}>{elt.text}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
                <div className=' d-md-block d-none position-absolute bottom-0 start-0 end-0'>
                    <Footer2/>
                </div>
                <div className='d-md-none d-block'>
                    <Footer2/>
                </div>
            </section>

        </div>
    )
}

export default Home