// Hero array
import c2 from '../assets/images/2.jpg'
import c3 from '../assets/images/accueil.jpg'
import c4 from '../assets/images/4.jpg'

// Portfolio images
import r1 from '../assets/images/rea/1.png'
import r2 from '../assets/images/rea/2.png'
import r3 from '../assets/images/rea/3.png'
import r4 from '../assets/images/rea/4.jpg'
import r5 from '../assets/images/rea/5.png'
import r6 from '../assets/images/rea/6.jpg'

// Links
import { contactLink, realisationsLink, agencyLink, serviceLink, quoteLink } from './links'


export const navLinks = [
    {nav:"L'agence", link:agencyLink},
    {nav:'Services', link: serviceLink},
    {nav:'Portfolio', link:realisationsLink},
    {nav:'Devis', link:quoteLink},
    {nav:'Contact', link:contactLink},
]


export const servicesArray = [
    {icon:"fa fa-hashtag", title:"STRATÉGIE & IDENTITÉ DE MARQUE", list:['Stratégie de communication', 'Brand audit', 'Brand communication', 'Stratégie Digitale', 'Direction artistique', 'Identité globale']},
    {icon:"fa fa-code", title:"ACCOMPAGNEMENT DIGITAL", list:['Stratégie Digitale', 'Site web', 'Applications mobiles', 'Hébergement', 'Gestion des réseaux sociaux']},
    {icon:"fa fa-pen-nib", title:"DESIGN & CRÉA", list:['Identité visuelle', 'Packaging', 'Edition', 'Création graphique']},
    {icon:"fa fa-play", title:"PRODUCTION AUDIOVISUELLE", list:["Films publicitaires & d'entreprises", 'Publi reportages', 'Contenus TV', 'Contenus digitaux', 'Documentaires']},
    {icon:"fa fa-scissors", title:"PRODUCTION TEXTILE", list:["Tenues d'entreprises", 'Blouses', 'Boubous', 'Gadgets']},
]


export const aboutArray = [
    {title:"NOTRE MISSION", text:"Valoriser votre image de marque, optimiser votre visibilité et booster votre croissance à travers des stratégies digitales sur-mesure."},
    {title:"NOS VALEURS", list:[
        {value:"Écoute", text:"Comprendre vos besoins et vos objectifs pour des solutions adaptées."},
        {value:"Innovation", text:"Utiliser les dernières tendances et technologies pour des campagnes impactantes."},
        {value:"Créativité", text:"Concevoir du contenu unique qui engage et captive votre audience."},
        {value:"Humanisme", text:"Mettre l’humain au cœur de chaque projet pour des relations durables et authentiques."},
    ]},
    {title:"NOTRE MANTRA", text:"Connecter les marques avec authenticité et créativité."},
]


export const heroCarouselArray = [
    {img:c3, text:"Au service des idées, même les plus folles..."},
    {img:c2, text:"C'est dans la vieille marmite qu'on fait la bonne sauce."},
    {img:c4, text:"Le partenaire idéal pour votre communication digitale."},
]


export const strategySolutions = {
    icon:"fa fa-hashtag",
    categories:[
        {name:"Brand Communication",
        list:[
            { image:r1, description:undefined, title:"Titre"},
            { image:r2, description:undefined, title:"Titre"},
        ],
        },
        {name:"Stratégie digitale",
            list:[
                { image:r3, description:undefined, title:"Titre"},
                { image:r4, description:undefined, title:"Titre"},
            ],
        },
        {name:"Création graphique",
            list:[
                { image:r6, description:undefined, title:"Titre"},
                { image:r5, description:undefined, title:"Titre"},
            ],
        },
    ]
}