import React, { useContext, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './views/Home';
import Contact from './views/Contact';
import Realisations from './views/Realisations';
import Agency from './views/Agency';
import Services from './views/Services';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { agencyLink, contactLink, quoteLink, realisationsLink, serviceLink } from './constants/links';
import { AppContexte } from './context/context';
import Quote from './views/Quote';

const RoutesWithAnimation = () => {
    const {setEndPoint} = useContext(AppContexte)

    const location = useLocation();

    useEffect(() => {
        window.scrollTo({top:0, behavior: 'instant'})
    }, [location]);

    useEffect(() => {
        setEndPoint(location.pathname)
    }, [location, setEndPoint]);

    return (
        <TransitionGroup>
            <CSSTransition key={location.key} timeout={300} classNames={"fade"}>
                <Routes location={location}>
                    <Route path='/'>
                        <Route index element={<Home/>} />
                        <Route path={agencyLink} element={<Agency/>} />
                        <Route path={contactLink} element={<Contact/>} />
                        <Route path={realisationsLink} element={<Realisations/>} />
                        <Route path={quoteLink} element={<Quote/>} />
                        <Route path={serviceLink} element={<Services/>} />
                    </Route>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default RoutesWithAnimation