import React, { useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useState } from 'react'
import emailjs from '@emailjs/browser';
import { publicKey, serviceId, templateId } from '../constants/links';
import { toast } from 'react-toastify';




const Quote = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: ''
    })
    const[onSend, setOnSend] = useState(false)

    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const validate = () => {
        let tempErrors = {}
        if (!formData.name) tempErrors.name = "Nom requis"
        if (!formData.email) {
            tempErrors.email = "Email requis"
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email non valide"
        }
        if (!formData.phone) {
            tempErrors.phone = "Téléphone requis"
        }
        if (!/^\+?[1-9]\d{1,14}$/.test(formData.phone)) {
            tempErrors.phone = "Téléphone non valide"
        }
        if (!formData.company) tempErrors.company = "Entreprise requis"
        if (!formData.message) tempErrors.message = "Message est requis"
        setErrors(tempErrors)
        return Object.keys(tempErrors).length === 0
    }


    const toastId = useRef(null);
    const handleSubmit = (e) => {
        e.preventDefault()
        if (validate()) {
            // Form is valid, submit the form
            toastId.current = toast.loading("Envoie en cours...", { 
                position: 'top-right',
            });
            setOnSend(true)
            emailjs.send(serviceId, templateId, formData, {publicKey: publicKey,})
            .then(() => {
                toast.dismiss(toastId.current)
                toastId.current = toast.success("Demande envoyée avec succès", {
                    isLoading: false,
                    autoClose: 5000,
                    closeOnClick: true,
                });
                setOnSend(false)
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    company: '',
                    message: ''
                })
            },
            (err) => {
                setOnSend(false)
                toast.dismiss(toastId.current)
                toastId.current = toast.error("Une erreur est survenue: "+err, {
                    isLoading: false,
                    hideProgressBar: false,
                    closeOnClick: true
                });
            });
        }
    }
    
    return (
        <div>
        
        
            {/* Header */}
            <Helmet>
                <title>Devis | CS COMMUNICATIONS, Together, we Believe...</title>
            </Helmet>

            {/* HERO */}
            <div className="bgcolor-primary">
                <div className="container h-100" >
                    <div className="d-flex justify-content-start align-items-center py-5 h-100" style={{minHeight: '70vh',  maxWidth: '992px'}}>
                        <div className='mt-5 h-100'>
                            <h1 data-aos="fade-right" data-aos-delay="300" className='cs-title fw-bold fs-2 text-white mb-3'>Votre projet commence ici. Nous lui donnons forme, vous lui donnez vie.
                                <span className="title-line-white my-4"></span>
                            </h1>
                            <p className='fs-9s text-white'  data-aos="fade-right" data-aos-delay="500">
                            Nous nous engageons à comprendre vos <strong className='fs-9s'>besoins unique</strong>s et à vous offrir une <strong className='fs-9s'>vision transparente</strong> de chaque étape de votre projet. Grâce à notre expertise, nous vous fournirons une <strong className='fs-9s'>estimation détaillée et personnalisée</strong>, conçue pour répondre à vos attentes et maximiser l'impact de vos actions digitales. Ensemble, nous créerons des solutions <strong className='fs-9s'>innovantes, performantes</strong> et parfaitement adaptées pour propulser votre entreprise vers le <strong className='fs-9s'>succès</strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            {/* FORM */}
            <div className="container my-4 my-md-5 pb-md-5 pb-3 overflow-hidden">
                <h1 data-aos="fade-up" data-aos-duration="700" className='cs-title fs-2  mt-3 mx-auto text-center fw-bold'>
                    Demandez votre devis
                    <span className="title-line mx-auto mt-2"></span>
                </h1>

                <div className="row justify-content-center align-items-stretch mt-5">
                    <div className="col-md-8 col-12">
                        <form action="" className='p-4 p-md-5 border border-2 bordercolor-primary bg-light shadow'>
                            <div className="row">
                                <div className="col-md-6 col-12 mb-2">
                                    <div className='inputBox'>
                                        <input 
                                            type='text' 
                                            name='name'
                                            value={formData.name}
                                            onChange={handleChange}
                                            className='w-100 fs-9s rounded-0 form-control' 
                                            required
                                        />
                                        <span>Nom</span>
                                        <i></i>
                                        {errors.name && <p className='text-danger fs-8s'>{errors.name}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-2">
                                    <div className='inputBox'>
                                        <input 
                                            type='text' 
                                            name='email'
                                            value={formData.email}
                                            onChange={handleChange}
                                            className='w-100 fs-9s rounded-0 form-control' 
                                            required
                                        />
                                        <span>Email</span>
                                        <i></i>
                                        {errors.email && <p className='text-danger fs-8s'>{errors.email}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-2">
                                    <div className='inputBox'>
                                        <input 
                                            type='text' 
                                            name='phone'
                                            value={formData.phone}
                                            onChange={handleChange}
                                            className='w-100 fs-9s rounded-0 form-control' 
                                            required
                                        />
                                        <span>Téléphone</span>
                                        <i></i>
                                        {errors.phone && <p className='text-danger fs-8s'>{errors.phone}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 mb-2">
                                    <div className='inputBox'>
                                        <input 
                                            type='text' 
                                            name='company'
                                            value={formData.company}
                                            onChange={handleChange}
                                            className='w-100 fs-9s rounded-0 form-control' 
                                            required
                                        />
                                        <span>Entreprise</span>
                                        <i></i>
                                        {errors.company && <p className='text-danger fs-8s'>{errors.company}</p>}
                                    </div>
                                </div>
                                <div className="col-12 mb-2">
                                    <div className='inputBox'>
                                        <textarea 
                                            type='text' 
                                            name='message'
                                            value={formData.message}
                                            onChange={handleChange}
                                            style={{minHeight: '10rem'}}
                                            className='w-100 fs-9s rounded-0 form-control' 
                                            required
                                        />
                                        <span>Mesage</span>
                                        <i></i>
                                        {errors.message && <p className='text-danger fs-8s'>{errors.message}</p>}
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <button type="submit" disabled={onSend} onClick={(e) => handleSubmit(e)} className=' px-2 py-1'>{onSend? 'Patientez' : 'Envoyer'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quote
