import React, { useState } from 'react'
import Modal from 'react-responsive-modal';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { placeholderImg } from '../constants/files';

const SolutionCard = ({ image, description, title, id, icon, category, objectif, result }) => {

    const[open, setOpen] = useState(false)

    return (
        <>
            <div className='position-relative sol-card overlay-container w-100 bg-white' style={{height: '15rem'}} id={id} onClick={()=>{setOpen(true)}} role='button'>
                <LazyLoadImage 
                className=' object-fit-contain border w-100 h-100' 
                src={image} 
                placeholder={<div className=' w-100 h-100'><img src={placeholderImg} className=' w-100 h-100 object-fit-cover' alt='' /></div>}
                effect="black-and-white"
                wrapperClassName=' h-100 w-100'
                alt={"CS COMMUNICATIONS " + title}  />
                <div className="overlay position-absolute top-0 w-100 h-100 bg-white bg-opacity-75 d-flex justify-content-center align-items-center flex-column">
                    <h3 className=' fs-6 color-primary'>{category} </h3>
                    <span>{title}</span>
                </div>
            </div>




            <Modal open={open} onClose={()=>setOpen(false)} center focusTrapped={false} >
                <div className=' row justify-content-around align-items-start'>
                    <LazyLoadImage 
                    className='object-fit-contain w-100 h-100 col-md-auto col-12' 
                    style={{maxHeight:"80vh"}}
                    alt='Stratégie Social Média' 
                    src={image} 
                    placeholder={<div className=' w-100 h-100'><img src={placeholderImg} className=' w-100 h-100 object-fit-cover' alt='' /></div>}
                    />
                    <div className=' col-md col-12'>
                        <h4 className=' text-center fw-bold color-primary text-capitalize mb-2'>{title}</h4>
                        <div className=' d-flex align-items-center'>
                            <i className={`${icon} color-primary fs-8s`}></i>
                            <span className=' fw-semibold fs-9s ms-1'>Objectif</span>
                        </div>
                        <p className=' fs-9s mb-3'>{objectif || "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde?"}</p>
                        <div className=' d-flex align-items-center'>
                            <i className={`${icon} color-primary fs-8s`}></i>
                            <span className=' fw-semibold fs-9s ms-1'>Résultat</span>
                        </div>
                        <p className=' fs-9s mb-3'>{result || "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor, aliquid ipsam numquam minus possimus dignissimos ipsum suscipit nihil soluta harum hic, illo nemo ullam repudiandae necessitatibus blanditiis ab officia unde? Sapiente, adipisci. Incidunt fugiat ipsum dolore distinctio temporibus. Totam iusto animi perspiciatis nisi explicabo alias deleniti nemo, optio adipisci amet laudantium voluptatum corrupti sint consequuntur in beatae illum! Tenetur, neque? Nesciunt fuga deleniti explicabo soluta atque!"}</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SolutionCard